
/* BASIC */

.login html {
  background-color: #ffffff;
}
  
.login body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
  /*background: url(../assets/antifascismo.jpg) no-repeat center center fixed;*/
}

.login a {
  color: #92badd;
  display:inline-block;
  text-decoration: none;
  font-weight: 400;
}
  
.login h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display:inline-block;
  margin: 40px 8px 10px 8px; 
  color: #cccccc;
}
  
  /* STRUCTURE */
  
.wrapper.login {
  display: flex;
  align-items: center;
  flex-direction: column; 
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.login .logo {margin: 10px 0}

.login #formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  text-align: center;
}
  
.login #formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}
  
/* TABS */

.login h2.inactive {
	color: #cccccc;
}

.login h2.active {
	color: #0d0d0d;
	border-bottom: 2px solid #5fbae9;
}
  
/* FORM TYPOGRAPHY*/
  
.login input[type=button], .login input[type=submit], .login input[type=reset] {
	background-color: #56baed;
	border: none;
	color: white;
	padding: 15px 80px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	text-transform: uppercase;
	font-size: 13px;
	-webkit-box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
	box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
	-webkit-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
	margin: 5px 20px 40px 20px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
  
.login input[type=button]:hover, .login input[type=submit]:hover, .login input[type=reset]:hover  {
	background-color: #39ace7;
}
  
.login input[type=button]:active, .login input[type=submit]:active, .login input[type=reset]:active  {
	-moz-transform: scale(0.95);
	-webkit-transform: scale(0.95);
	-o-transform: scale(0.95);
	-ms-transform: scale(0.95);
	transform: scale(0.95);
}
  
.login input[type=text], .login input[type=password] {
	background-color: #f6f6f6;
	border: none;
	color: #0d0d0d;
	padding: 15px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 5px;
	width: 85%;
	border: 2px solid #f6f6f6;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	-webkit-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
}
  
.login input[type=text]:focus, .login input[type=password]:focus {
	background-color: #fff;
	border-bottom: 2px solid #5fbae9;
}
  
.login input[type=text]:placeholder, .login input[type=password]:placeholder {
	
}
  
/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.login .fadeInDown {
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
  
@-webkit-keyframes fadeInDown {
	0% {
	  opacity: 0;
	  -webkit-transform: translate3d(0, -100%, 0);
	  transform: translate3d(0, -100%, 0);
	}
	100% {
	  opacity: 1;
	  -webkit-transform: none;
	  transform: none;
	}
}
  
@keyframes fadeInDown {
	0% {
	  opacity: 0;
	  -webkit-transform: translate3d(0, -100%, 0);
	  transform: translate3d(0, -100%, 0);
	}
	100% {
	  opacity: 1;
	  -webkit-transform: none;
	  transform: none;
	}
}
  
/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
  
.login .fadeIn {
	opacity:0;
	-webkit-animation:fadeIn ease-in 1;
	-moz-animation:fadeIn ease-in 1;
	animation:fadeIn ease-in 1;

	-webkit-animation-fill-mode:forwards;
	-moz-animation-fill-mode:forwards;
	animation-fill-mode:forwards;

	-webkit-animation-duration:1s;
	-moz-animation-duration:1s;
	animation-duration:1s;
}
  
.login .fadeIn.first {
	-webkit-animation-delay: 0.4s;
	-moz-animation-delay: 0.4s;
	animation-delay: 0.4s;
}

.login .fadeIn.second {
	-webkit-animation-delay: 0.6s;
	-moz-animation-delay: 0.6s;
	animation-delay: 0.6s;
}

.login .fadeIn.third {
	-webkit-animation-delay: 0.8s;
	-moz-animation-delay: 0.8s;
	animation-delay: 0.8s;
}

.login .fadeIn.fourth {
	-webkit-animation-delay: 1s;
	-moz-animation-delay: 1s;
	animation-delay: 1s;
}
  
/* Simple CSS3 Fade-in Animation */
.login .underlineHover:after {
	display: block;
	left: 0;
	bottom: -10px;
	width: 0;
	height: 2px;
	background-color: #56baed;
	content: "";
	transition: width 0.2s;
}

.login .underlineHover:hover {
	color: #0d0d0d;
}

.login .underlineHover:hover:after{
	width: 100%;
}
  
/* OTHERS */
.login *:focus {
	outline: none;
} 

.login #icon {
	width:60%;
}